import React, { FC, useCallback, useMemo, useRef, useState } from "react";
import { map, includes, filter } from "lodash";
import styled from "@emotion/styled";
import SinonKeyboard from "../keyboard/keyboard.component";
import AWordToGuess from "./aWordToguess";
import { Char } from "../common.types";
import { useParams } from "react-router-dom";
import { Fireworks } from "@fireworks-js/react";
import type { FireworksHandlers } from "@fireworks-js/react";
import { convertCodeToGuess, hebrewWithFinalLetter } from "../utils/utils";
import ErrorScore from "./errorScore";

type GuessMode = "synonym" | "word";

const GuessPage: FC = () => {
  const [guessMode, setGuessMode] = useState<GuessMode>("synonym");
  const [theWordBeingGuessed, setTheWordBeingGuessed] = useState<string>("");
  // const [errorCount, setErrorCount] = useState<number>(0);

  const [guessedWordsSynonyms, setGuessedWordsSynonyms] = useState<
    Array<string>
  >([]);

  const { guessString } = useParams<{
    guessString: string;
  }>();

  const refFireWorks = useRef<FireworksHandlers>(null);

  const guess = useMemo(() => {
    return convertCodeToGuess(guessString);
  }, [guessString]);

  console.log("guess", guess);

  const guessSynonym = useCallback(
    (newGuess: string) => {
      const antoherChar = hebrewWithFinalLetter(newGuess);
      if (antoherChar) {
        setGuessedWordsSynonyms([
          ...guessedWordsSynonyms,
          newGuess[0] as Char,
          antoherChar,
        ]);
      } else {
        setGuessedWordsSynonyms([...guessedWordsSynonyms, newGuess[0] as Char]);
      }
    },
    [guessedWordsSynonyms, setGuessedWordsSynonyms]
  );

  const greenButtons = useMemo(() => {
    const guesssdSynonymsWithhebrew = map(
      guess.synonyms.join(),
      (synonymLetter) =>
        synonymLetter + (hebrewWithFinalLetter(synonymLetter) ?? "")
    ).join();

    return filter(guessedWordsSynonyms, (letter) =>
      includes(guesssdSynonymsWithhebrew, letter)
    ).join(" ");
  }, [guessedWordsSynonyms, guess.synonyms]);

  const redButtons = useMemo(() => {
    const guesssdSynonymsWithhebrew = map(
      guess.synonyms.join(),
      (synonymLetter) =>
        synonymLetter + (hebrewWithFinalLetter(synonymLetter) ?? "")
    ).join();

    return filter(
      guessedWordsSynonyms,
      (letter) => false === includes(guesssdSynonymsWithhebrew, letter)
    ).join(" ");
  }, [guessedWordsSynonyms, guess.synonyms]);

  const guessWord = useCallback(
    (newGuess: string) => {
      console.log("newGuess", newGuess);
      if (newGuess === "{bksp}") {
        if (theWordBeingGuessed.length >= 1) {
          setTheWordBeingGuessed(theWordBeingGuessed.slice(0, -1));
        }
      } else {
        setTheWordBeingGuessed(theWordBeingGuessed + newGuess);
      }
    },
    [setTheWordBeingGuessed, theWordBeingGuessed]
  );

  const guessStatus = useMemo(() => {
    if (theWordBeingGuessed.length === guess.wordToGuess.length) {
      if (theWordBeingGuessed === guess.wordToGuess) {
        return true;
      } else {
        return false;
      }
    }
    return undefined;
  }, [theWordBeingGuessed, guess.wordToGuess]);
  console.log("redButtons", redButtons);
  console.log("greenButtons", greenButtons);
  return (
    <Container>
      <ContentContainer>
        {guessStatus && (
          <Fireworks
            ref={refFireWorks}
            options={{ opacity: 0.5 }}
            style={{
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              position: "fixed",
              background: "#0000ff99",
            }}
          />
        )}
        <Title>{guess.lang === "he" ? "המילים הרומזות" : "Hint words"}</Title>
        {map(guess.synonyms, (word: string, index: number) => (
          <AWordToGuess
            lang={guess.lang}
            onClick={() => setGuessMode("synonym")}
            key={index}
            letters={map(word.split(""), (letter) =>
              guessedWordsSynonyms.includes(letter) ? letter : undefined
            )}
            guess={guessMode === "synonym" ? guessSynonym : guessWord}
          />
        ))}
        <Space />
        <Title>
          {guess.lang === "he" ? " המילה לנחש" : "The word to guess"}
        </Title>
        <AWordToGuess
          lang={guess.lang}
          onClick={() => setGuessMode("word")}
          key={"The word to guess"}
          letters={[
            ...theWordBeingGuessed.split(""),
            ...(theWordBeingGuessed.length < guess.wordToGuess.length
              ? Array<string>(
                  guess.wordToGuess.length - theWordBeingGuessed.length
                )
              : []),
          ]}
          // letters={[...theWordBeingGuessed.split(""),[...(theWordBeingGuessed.length<guess.wordToGuess? [...Array<string>(guess.wordToGuess.length - theWordBeingGuessed.length]:[])]}
          guess={guessSynonym}
        />
        <ErrorScore numberOfErrors={/*errorCount*/ 0} />
        <Title>
          {guessStatus === undefined
            ? undefined
            : guessStatus
            ? guess.lang === "he"
              ? "ניחשת נכון"
              : "You Guessed it"
            : guess.lang === "he"
            ? "טעות בידך"
            : "You Did not guess it"}
        </Title>
      </ContentContainer>
      <KeyboardContainer>
        {guessMode === "synonym" ? (
          <SinonKeyboard
            lang={guess.lang}
            onKeyPress={guessSynonym}
            redButtons={redButtons}
            greenButtons={greenButtons}
          />
        ) : (
          <SinonKeyboard lang={guess.lang} onKeyPress={guessWord} />
        )}
      </KeyboardContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 255, 0.6);
  overflow: none; ;
`;

const ContentContainer = styled.div`
  width: 100%;
  height: calc(100vh - 200px);

  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: none; ;
`;

const Title = styled.div`
  font-size: 30px;
  text-transform: uppercase;
  margin-top: 30px;
  text-align: center;
`;
const Space = styled.div`
  height: 80px;
`;

const KeyboardContainer = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
`;

export default GuessPage;
