import { FC, useMemo } from "react";
import { map, reverse } from "lodash";
import styled from "@emotion/styled";
import ALetterToGuess from "./aLetterToGuess";

interface AWordToGuessProps {
  guess: (newGuess: string) => void;
  letters: Array<string | undefined>;
  onClick: () => void;
  lang: "he" | "eng";
}

const AWordToGuess: FC<AWordToGuessProps> = ({
  lang,
  letters,
  guess,
  onClick,
}) => {
  const lettersToShow = useMemo(() => {
    const lettersToShowTemp = [...letters];

    if (lang === "he") {
      reverse(lettersToShowTemp);
    }
    return lettersToShowTemp;
  }, [letters, lang]);
  return (
    <Container>
      {map(lettersToShow, (letter, index) => (
        <ALetterToGuess
          key={index}
          letter={letter}
          guess={guess}
          onClick={onClick}
        />
      ))}
      {/* <RICIBs
        amount={letters.length}
        autoFocus
        handleOutputString={handleOutput}
        inputProps={inputProps}
        inputRegExp={inputReg}
      /> */}
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: rgba(0, 0, 255, 0);
`;

export default AWordToGuess;
