import React from "react";

import "./App.css";
import GuessPage from "./guessPage/guessPage";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import CreateNewGuess from "./createNewGuess";

const router = createBrowserRouter([
  { path: "/Edit", element: <CreateNewGuess /> },
  { path: "/he/Edit", element: <CreateNewGuess lang="he" /> },
  { path: "/:guessString?", element: <GuessPage /> },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
