import React, { FC, useMemo, useRef, useState } from "react";
import { map } from "lodash";
import styled from "@emotion/styled";
import { convertGuessToCode } from "./utils/utils";
const CreateNewGuess: FC<{ lang?: "he" | "eng" }> = ({ lang = "eng" }) => {
  const [word, setWord] = useState<string | undefined>(undefined);
  const [wordsSynonyms, setWordsSynonyms] = useState<Array<string>>([]);
  const link = useMemo(() => {
    if (word === undefined) {
      return "";
    }
    return convertGuessToCode({
      wordToGuess: word,
      synonyms: wordsSynonyms,
      lang,
    });
  }, [word, wordsSynonyms, lang]);

  const inputRef = useRef<HTMLInputElement | null>(null);
  if (word === undefined) {
    return (
      <Container>
        <Title>{lang === "he" ? "הכנס מילה לניחוש" : "Create New Guess"}</Title>
        <NewInput ref={inputRef} />
        <NewButton
          onClick={() => {
            if (inputRef.current) {
              setWord(inputRef.current?.value);
            }
          }}
        >
          {lang === "he" ? "הכנס" : "create"}
        </NewButton>
      </Container>
    );
  }

  return (
    <Container>
      <Title>{lang === "he" ? "מילה לניחוש" : "Word To guess"}</Title>
      <WordToGuess>{`${word}`}</WordToGuess>
      <Space />
      <Title>{lang === "he" ? "מילים נרדפות" : "Synonyms"}</Title>
      {map(wordsSynonyms, (synonym) => (
        <WordToGuess>{synonym}</WordToGuess>
      ))}

      <NewInput ref={inputRef} />
      <NewButton
        onClick={() => {
          if (inputRef.current) {
            setWordsSynonyms([...wordsSynonyms, inputRef.current.value]);
            inputRef.current.value = "";
          }
        }}
      >
        {lang === "he" ? "הוסף מילה" : "Add word"}
      </NewButton>

      <NewLink href={`/${link}`}>
        {lang === "he"
          ? "שחק משחק עם המילים האלה"
          : "Click Here to Play with these words"}
      </NewLink>
    </Container>
  );
};

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 255, 0.6);
  overflow: none;
  align-items: center;
`;

const Title = styled.div`
  font-size: 30px;
  text-transform: uppercase;
  margin-top: 30px;

  text-align: center;
`;

const WordToGuess = styled.div`
  font-size: 28px;
  text-transform: uppercase;
  margin-top: 30px;
  text-align: center;
`;

const NewLink = styled.a`
  margin: 0px 20px;
  max-width: 80vw;
  font-size: 22px;
  margin-top: 30px;
  text-align: center;
`;

const Space = styled.div`
  height: 80px;
`;

const NewInput = styled.input`
  width: 80%;
  margin: 20px;
  height: 50px;
`;

const NewButton = styled.button`
  margin: 20px;
  align-self: center;
  width: 80%;
  height: 50px;
  font-size: 22px;
`;

export default CreateNewGuess;
