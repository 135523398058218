import { FC } from "react";
import styled from "@emotion/styled";
import { map } from "lodash";
// const inputReg = new RegExp("[a-z]");
import { ReactComponent as Xsymbol } from "./x-symbol-svgrepo-com.svg";

interface ErrorScoreProps {
  numberOfErrors: number;
}

const ErrorScore: FC<ErrorScoreProps> = ({ numberOfErrors }) => {
  return (
    <Container>
      {map([1, 1, 1, 1, 1], (item, index) =>
        numberOfErrors <= index ? (
          <Guess key={index} />
        ) : (
          <BadGuess>
            <Xsymbol />
          </BadGuess>
        )
      )}
    </Container>
  );

  //   }
  // return (
  //   <InputLetter
  //     autoFocus={true}
  //     disabled={letter !== undefined}
  //     value={letter}
  //     onChange={(event) => {
  //       guess(event.target.value);
  //       event.target.value = "";
  //     }}
  //   ></InputLetter>
  // );
};

// const InputLetter = styled.input`
//   text-align: center;
//   width: 30px;
//   height: 40px;
//   margin-right: 1px;
//   margin-left: 1px;
// `;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`;

const Guess = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 30px;
  height: 40px;
  margin-right: 1px;
  margin-left: 1px;
  vertical-align: center;
  background-color: transparent;
`;

const BadGuess = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 30px;
  height: 40px;
  margin-right: 1px;
  margin-left: 1px;
  vertical-align: center;
  background-color: red;
`;

export default ErrorScore;
