import React, { FC } from "react";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import "./myKeyBoard.css";
import { compact, isEmpty } from "lodash";

interface SinonKeyboardProps {
  greenButtons?: string;
  redButtons?: string;
  onKeyPress: (newLetter: string) => void;
  lang: "he" | "eng";
}

const EnglishLayout = [
  "q w e r t y u i o p",
  "a s d f g h j k l",
  "{bksp} z x c v b n m {enter}",
];
const HebrewLayout = [
  "\u05e7 \u05e8 \u05d0 \u05d8 \u05d5 \u05df \u05dd \u05e4",
  "\u05e9 \u05d3 \u05d2 \u05db \u05e2 \u05d9 \u05d7 \u05dc \u05da \u05e3",
  "{bksp} \u05d6 \u05e1 \u05d1 \u05d4 \u05e0 \u05de \u05e6 \u05ea \u05e5 {enter}",
];

const SinonKeyboard: FC<SinonKeyboardProps> = ({
  greenButtons,
  redButtons,
  onKeyPress,
  lang,
}) => {
  const buttonThemes = compact([
    redButtons && !isEmpty(redButtons)
      ? {
          class: "hg-red",
          buttons: redButtons,
        }
      : null,
    greenButtons && !isEmpty(greenButtons)
      ? {
          class: "hg-green",
          buttons: greenButtons,
        }
      : null,
  ]);
  console.log("buttonThemes", buttonThemes);
  return (
    <Keyboard
      onKeyPress={onKeyPress}
      layout={{
        default: lang === "he" ? HebrewLayout : EnglishLayout,
      }}
      display={{
        "{bksp}": "delete",
        "{enter}": "enter",
        "@": "at",
      }}
      theme={"hg-theme-default hg-layout-default myTheme"}
      buttonTheme={buttonThemes}
    />
  );
};

export default SinonKeyboard;
