export interface GuessProps {
  wordToGuess: string;
  synonyms: string[];
  lang: "he" | "eng";
}

export function convertGuessToCode(guess: GuessProps): string {
  const string = JSON.stringify(guess);
  const codeUnits = Uint16Array.from(
    { length: string.length },
    (element, index) => string.charCodeAt(index)
  );
  const charCodes = new Uint8Array(codeUnits.buffer);

  let result = "";
  charCodes.forEach((char) => {
    result += String.fromCharCode(char);
  });
  return btoa(result);
}

export function convertCodeToGuess(code?: string): GuessProps {
  if (code === undefined) {
    return {
      wordToGuess: "sinon",
      synonyms: ["best", "game", "of", "this", "year"],
      lang: "eng",
    };
  }
  const binary = atob(code);
  const bytes = Uint8Array.from({ length: binary.length }, (element, index) =>
    binary.charCodeAt(index)
  );
  const charCodes = new Uint16Array(bytes.buffer);

  let result = "";
  charCodes.forEach((char) => {
    result += String.fromCharCode(char);
  });

  return JSON.parse(result);
}

export function hebrewWithFinalLetter(letter: string): string | undefined {
  if (letter[0] === undefined) {
    return undefined;
  }
  switch (letter) {
    case "\u05e3": {
      //ף
      return "\u05e4";
    }
    case "\u05e4": {
      //פ
      return "\u05e3";
    }
    case "\u05da": {
      //ך
      return "\u05db";
    }
    case "\u05db": {
      //כ
      return "\u05da";
    }
    case "\u05dd": {
      //ם
      return "\u05de";
    }
    case "\u05de": {
      //מ
      return "\u05dd";
    }
    case "\u05e5": {
      //ץ
      return "\u05e6";
    }
    case "\u05e6": {
      //צ
      return "\u05e5";
    }
    case "\u05e0": {
      //ן
      return "\u05df";
    }
    case "\u05df": {
      //נ
      return "\u05e0";
    }
    default:
      return undefined;
  }
}
